@import "~antd/dist/antd.less";

@font-face {
  font-family: "FuturaPT";
  src: local("FuturaPT"), url(/fonts/FuturaPT/FuturaPTBook.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "FuturaPT", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col-left,
.col-right,
.col-1-2 {
  width: calc(50% - 1rem);
}

.col-1-5 {
  width: calc(20% - 0.5rem);
}

.col-2-5 {
  width: calc(40% - 0.5rem);
}

.col-1-4 {
  width: calc(25% - 1rem);
}

.col-1-3 {
  width: calc(33.33% - 1rem);
}

.col-2-3 {
  width: calc(66.66% - 1rem);
}

.col-full {
  width: 100%;
}

.primary-gradient {
  background: linear-gradient(
    180deg,
    rgba(242, 0, 58, 100) 0%,
    rgba(242, 0, 58, 0.37) 100%
  );
}

.secondary-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #555;
}

.uppercase {
  text-transform: uppercase;
}

.text-white {
  color: white;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
}

.text-gray {
  color: #707479;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.o-8 {
  opacity: 0.8;
}

.o-6 {
  opacity: 0.6;
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 3rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.h-full {
  height: 100%;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-12 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.w-full {
  width: 100%;
}

.onyx-breadcrumb {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 25px;
}

.ant-table-wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 5px;
}

.ant-table {
  background: transparent;

  table {
    border-radius: 5px;
  }
}

.ant-table-content {
  thead > tr > th {
    border-bottom: inherit;
    background: transparent;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  }

  .ant-table-tbody {
    tr:nth-child(even) {
      background: #2b2e35;
    }

    tr:nth-child(odd) {
      background: #25272e;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: inherit;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    opacity: 0.6;
    text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
  }

  .ant-table-tbody > tr:hover > td {
    background: unset;
  }

  .ant-table-tbody > .ant-table-row-selected > td {
    background: unset;
  }
}

.ant-checkbox > .ant-checkbox-inner {
  background: transparent;
}

.ant-select.onyx-select {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);

  .ant-select-selector {
    height: calc(25px + 2rem);
    background: #202225;
    border-radius: 5px;
    color: #fff;
    border: inherit;
    .ant-select-selection-search {
      input {
        height: inherit;
        padding: 1rem 0;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
      }
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      padding: 1rem 0;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    }
  }

  .ant-select-arrow {
    .anticon-down,
    .anticon-search {
      svg {
        fill: #aaa;
      }
    }
  }
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  background: #202225;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #3a3a3a;
  }

  .ant-select-item-option-selected {
    background: #202225;
  }

  .ant-select-item-option {
    padding: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: white;
  }

  .ant-empty-description {
    color: white;
  }
}

.ant-picker.onyx-date-picker {
  padding: 0;
  background: #202225;
  border: inherit;

  .ant-picker-input {
    height: calc(25px + 2rem);
    padding: 0 1rem;

    input {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
      background: #202225;
      color: #fff;
    }

    .ant-picker-suffix > span > svg {
      fill: #aaa;
    }
  }
}

.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft
  .ant-picker-panel-container {
  background: #202225;
  .ant-picker-panel .ant-picker-date-panel {
    .ant-picker-header button {
      color: #fff;
    }

    .ant-picker-body .ant-picker-content {
      thead {
        th {
          color: rgba(255, 255, 255, 0.65);
        }
      }

      tr {
        td.ant-picker-cell {
          color: rgba(255, 255, 255, 0.25);
        }
        td.ant-picker-cell.ant-picker-cell-in-view {
          color: rgba(255, 255, 255, 0.65);
        }

        td:hover .ant-picker-cell-inner {
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

@default: [object Object];